import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap"
import parse from "html-react-parser"
import $ from "jquery"
import ModalTiny from "react-bootstrap/Modal"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"

const CampaignLandingTerms = (props) => {

    // var items = []
    // var type_options = ''

    // if (props.Add_Select_Options?.Add_Options) {
    //     props.Add_Select_Options.Add_Options.map((item, index) => {
    //         items += item.Option + ","
    //     })
    //     type_options = items.replace(/,\s*$/, "").split(',')
    // }


    const [modalSendfrndformOpen, setSendfrndformOpen] = React.useState(false);
    const openSendfrndformModal = () => {
        setSendfrndformOpen(true);
    }
    const closeSendfrndformModal = () => {
        setSendfrndformOpen(false);
    }

    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext)
        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey)
        )
        const isCurrentEventKey = currentEventKey === eventKey
        return (
            <button
                type="button"
                className={
                    isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
                }
                onClick={decoratedOnClick}
            >
                {children}
                <i
                    className={
                        isCurrentEventKey ? "icon-up" : "icon-down"
                    }
                ></i>
            </button>
        )
    }
    return (
        <React.Fragment>
            <div className="over-60-checkbox col-md-12 terms-check mb-4">
                <label className="check-card mb-4 pb-2 checkbox-div">
                    <input required={true} className="" type="checkbox" id="terms" name="terms" value="terms" />I agree to the {(props.Add_New_Accordion && props.Add_New_Accordion.length > 0) || props.Terms_and_Conditions_Intro ? <a href="javascript:void(0)" className="form-text-link" onClick={openSendfrndformModal}>Terms & Conditions</a> : <a href="/terms-and-conditions" className="form-text-link" target="_blank">Terms & Conditions</a>} and <a href="/privacy-policy" class="form-text-link" target="_blank"> Privacy Policy </a>
                    <span className="check-mark"></span>
                </label>
            </div>

            {(props.Add_New_Accordion && props.Add_New_Accordion.length > 0) || props.Terms_and_Conditions_Intro ?
                <ModalTiny show={modalSendfrndformOpen} onHide={closeSendfrndformModal} className="book-a-viewing-modal modal-main-form modal campaign-terms">
                    <ModalTiny.Header closeButton className="contact-close-btn">
                        {props.Terms_and_Conditions_Intro &&
                            <ModalTiny.Title className="w-100">
                                {parse(props.Terms_and_Conditions_Intro)}
                            </ModalTiny.Title>
                        }
                    </ModalTiny.Header>

                    <ModalTiny.Body className="event-section">
                        <Accordion>
                            {props.Add_New_Accordion && props.Add_New_Accordion.map((item, index) => {
                                return (
                                    <Card>
                                        <div className="accordion-header card-header">
                                            <ContextAwareToggle eventKey={index + 1} className="accordion-header">
                                                <h4>{index + 1}. {item.Title}</h4>
                                            </ContextAwareToggle>
                                        </div>
                                        <Accordion.Collapse eventKey={index + 1}>
                                            <div className="accordion-card accordion-card-first card-body">

                                                <div className="accordion-list">
                                                    {parse(item.Content)}
                                                </div>

                                            </div>
                                        </Accordion.Collapse>
                                    </Card>

                                )
                            })}
                        </Accordion>
                    </ModalTiny.Body>

                </ModalTiny> : ""
            }

        </React.Fragment >
    )
}

export default CampaignLandingTerms